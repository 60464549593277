import logo from 'assets/logo.png';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';

export const AboutUs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <WebLayout>
      <Head title="About Us" description="Ared Group" />
      <div className="py-16 bg-gradient-to-br from-white to-gray-50">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-16">
          {/* Header Section */}
          <div className="text-center mb-16">
            <img src={logo} alt="Ared Group Logo" className="w-24 h-24 mx-auto mb-4" />
            <h2 className="text-5xl font-extrabold text-gray-800">About Ared Group</h2>
            <p className="mt-4 text-xl text-gray-600 max-w-2xl mx-auto">
              Empowering communities through sustainable and innovative technology solutions.
            </p>
          </div>

          {/* Vision, Story, Approach, Mission, and Values as Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="rounded-lg shadow-lg p-8 bg-white hover:shadow-2xl transition-shadow">
              <h3 className="text-2xl font-semibold text-primary-600 mb-3">Our Vision</h3>
              <p className="text-gray-700">
                To build a digitally inclusive world where technology empowers every individual and
                community to realize their full potential, especially in underserved regions.
              </p>
            </div>

            <div className="rounded-lg shadow-lg p-8 bg-white hover:shadow-2xl transition-shadow">
              <h3 className="text-2xl font-semibold text-primary-600 mb-3">Our Story</h3>
              <p className="text-gray-700">
                Founded with a passion to make technology accessible, Ared Group started as a bold
                initiative to bridge the digital divide in Africa. Over the years, we've transformed
                communities by providing technology hubs, enhancing digital literacy, and driving
                economic growth through job creation and education.
              </p>
            </div>

            <div className="rounded-lg shadow-lg p-8 bg-white hover:shadow-2xl transition-shadow">
              <h3 className="text-2xl font-semibold text-primary-600 mb-3">Our Approach</h3>
              <p className="text-gray-700">
                At Ared, we believe in a human-centered approach to technology. Our solutions are
                designed to be affordable, scalable, and impactful, focusing on mobile and solar
                energy systems that meet the needs of communities without access to reliable power
                or connectivity.
              </p>
            </div>

            <div className="rounded-lg shadow-lg p-8 bg-white hover:shadow-2xl transition-shadow">
              <h3 className="text-2xl font-semibold text-primary-600 mb-3">Our Mission</h3>
              <p className="text-gray-700">
                To empower underserved communities through sustainable technology that drives
                economic empowerment and digital inclusion, creating opportunities for growth and
                self-sufficiency.
              </p>
            </div>

            <div className="md:col-span-2 rounded-lg shadow-lg p-8 bg-white hover:shadow-2xl transition-shadow">
              <h3 className="text-2xl font-semibold text-primary-600 mb-3">Our Values</h3>
              <p className="text-gray-700">
                Integrity, innovation, community, and sustainability are at the core of everything
                we do. We are committed to creating solutions that not only enhance lives but also
                respect and protect our environment, ensuring a better future for the next
                generation.
              </p>
            </div>
          </div>

          {/* Call to Action */}
          <div className="text-center mt-16">
            <button
              onClick={() => navigate('/contact-us')}
              className="px-8 py-3 bg-[#FCB414] text-white rounded-full font-medium text-lg shadow-md hover:bg-primary-500 transition-colors"
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
