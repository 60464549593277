import React, { useEffect } from 'react';
import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import CookieConsent, {
  getCookieConsentValue,
  resetCookieConsentValue,
} from 'react-cookie-consent';

import 'sweetalert2/src/sweetalert2.scss';

function App() {
  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === 'false') {
      // Reset tracking preferences or any other settings if user declined cookies
    }
  }, []);

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
