import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import MotivationRafiki from 'assets/financial-bro.svg';
import { CheckCircle, Shield, DollarSign, ThumbsUp } from 'react-feather';
import { useEffect } from 'react';
import HomeMockup from 'assets/WelcomeScreen-right.png';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) navigate('/app');
  }, [user]);

  const handleStart = () => {
    window.location.href = 'mailto:contact@aredgroup.com';
  };

  return (
    <WebLayout>
      <Head title="Home" description="RafikiPay - Enabling Last-Mile Financial Access" />
      {/* Background with gradient and shapes */}
      <div className="relative overflow-hidden bg-gradient-to-r from-[#01BAB4] to-[#004454] min-h-screen flex items-center">
        <div className="absolute inset-0 opacity-30">
          <svg className="h-full w-full" viewBox="0 0 1440 320" preserveAspectRatio="none">
            <path
              fill="#ffffff"
              d="M0,64L30,85.3C60,107,120,149,180,170.7C240,192,300,192,360,170.7C420,149,480,107,540,90.7C600,75,660,85,720,96C780,107,840,117,900,133.3C960,149,1020,171,1080,176C1140,181,1200,171,1260,160C1320,149,1380,139,1410,134.7L1440,128L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320H0V64Z"
            ></path>
          </svg>
        </div>
        {/* Header Section */}
        <div className="flex flex-col md:flex-row items-center justify-between mx-6 md:mx-24 my-8 z-10">
          <div className="md:w-1/2 space-y-4">
            <h1 className="text-4xl md:text-5xl font-extrabold text-white">
              Ignite Your Financial Future
            </h1>
            <p className="text-white text-lg md:text-xl">
              Introducing RafikiPay, ARED's digital financial services platform designed to bring
              secure, transparent, and accessible financial solutions to the last mile.
            </p>
            <Button onClick={handleStart} size="lg">
              Get Started
            </Button>
          </div>
          <div className="md:w-1/2 mt-6 md:mt-0">
            <img src={MotivationRafiki} alt="Financial Empowerment" className="w-full" />
          </div>
        </div>
      </div>
       {/* Features Section */}
       <section className="mx-6 md:mx-24 mt-12 mb-24">
        <h2 className="text-3xl md:text-4xl font-extrabold text-[#01BAB4] text-center">Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <FeatureCard
            icon={<DollarSign size={40} className="text-[#01BAB4]" />}
            title="Borrow"
            description="Request a loan with terms that suit you best, while connecting with trusted lenders on a secure platform."
          />
          <FeatureCard
            icon={<ThumbsUp size={40} className="text-[#01BAB4]" />}
            title="Lend"
            description="Registered lenders can view and accept loan requests, creating opportunities for secure partnerships."
          />
          <FeatureCard
            icon={<CheckCircle size={40} className="text-[#01BAB4]" />}
            title="Transactions"
            description="Keep track of all active, past, and overdue loans with real-time insights for better financial decisions."
          />
        </div>
      </section>
      <section className="relative overflow-hidden bg-gradient-to-r from-[#01BAB4] to-[#00BFAE] py-12">
        <div className="absolute inset-0">
          <div className="shape bg-blue-200 opacity-30 rounded-full h-96 w-96 transform -translate-x-1/4 -translate-y-1/4"></div>
          <div className="shape bg-purple-200 opacity-30 rounded-full h-64 w-64 transform translate-x-1/4 translate-y-1/4"></div>
        </div>
        <div className="mx-4 md:mx-48 grid grid-cols-1 md:grid-cols-3 gap-4 relative z-10">
          <div>
            <div className="text-4xl md:text-6xl font-black text-white">RafikiPay</div>
            <div className="text-lg md:text-2xl text-white">
              A digital financial services platform designed to bring secure, transparent, and
              accessible financial solutions to the last mile.
            </div>
            <div>
              <img src={HomeMockup} alt="Home Mockup" className="h-[40vh] md:h-[40vh] mx-auto" />
            </div>
          </div>
          <div className="col-span-2 md:ml-24">
            <div className="bg-primary-100 p-8 rounded-2xl shadow-lg">
              <div className="font-bold text-2xl mb-3 text-white">
                Join thousands of happy users!
              </div>
              <div className="text-lg md:text-2xl text-white">
                We are constantly looking for ways to make our lives easier and safer, and RafikiPay
                is the App that nails it. Until now, people seeking professional and transparent
                services from Money Lenders have had no designated service center and more often
                than not have ended up in the hands of ‘sharks’ as we are commonly referred to.
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="mx-6 md:mx-24 my-16">
        <h2 className="text-3xl md:text-4xl font-extrabold text-[#01BAB4] text-center">Benefits</h2>
        <div className="grid grid-cols-1 gap-8 md:gap-16 mt-8">
          <BenefitCard
            // icon={<Shield size={40} className="text-[#01BAB4]" />}
            title="Secure & Transparent"
            description="With RafikiPay’s transparent processes, all transactions are clear and organized, providing peace of mind for both parties."
          />
          <BenefitCard
            // icon={<DollarSign size={40} className="text-[#01BAB4]" />}
            title="Flexible Terms"
            description="Empowering borrowers with options to negotiate terms that are fair and beneficial for both sides."
            isReversed
          />
          <BenefitCard
            // icon={<ThumbsUp size={40} className="text-[#01BAB4]" />}
            title="Investor-Friendly"
            description="RafikiPay encourages a range of participants—from private investors to experienced lenders."
          />
          <BenefitCard
            // icon={<Shield size={40} className="text-[#01BAB4]" />}
            title="Collateral Protection"
            description="Ensuring security of collateral assets to provide confidence and trust for all transactions."
            isReversed
          />
        </div>
      </section>
    </WebLayout>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="flex flex-col items-center bg-gray-100 p-8 rounded-xl text-center shadow-md">
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

const BenefitCard = ({ icon, title, description, isReversed }) => (
  <div
    className={`flex ${
      isReversed ? 'md:flex-row-reverse' : 'md:flex-row'
    } flex-col items-center gap-6 md:gap-8 p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 border-2 border-transparent bg-clip-padding`}
  >
    <div className="flex-shrink-0 p-4 rounded-full bg-[#01BAB4] text-white w-16 h-16 flex items-center justify-center shadow-md">
      {icon}
    </div>
    <div className="md:w-3/4">
      <h4 className="text-xl font-semibold text-[#01BAB4] mb-2">{title}</h4>
      <p className="text-gray-700 text-base leading-relaxed">{description}</p>
    </div>
  </div>
);

