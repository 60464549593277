import logo from 'assets/logo.png';
import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <Link to="/">
      <img className="h-16" src={logo} alt="Workflow" />
    </Link>
  );
};
