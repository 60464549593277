import React, { useEffect, useState } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { Mail, MapPin } from 'react-feather';
import { Button } from 'components/Elements';

export const ContactUs = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const { name, email, message } = formData;

  const address = `ARED Group Headquarters\nP.O Box 134920, Kampala\nPlot 14051, Twin Plaza, Mbogo Road\nKira Town`;
  const toEmail = 'info@ared-group.com';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:${toEmail}?subject=Contact%20Form&body=Name:%20${encodeURIComponent(
      name
    )}%0D%0AEmail:%20${encodeURIComponent(email)}%0D%0AMessage:%20${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <WebLayout>
      <Head
        title="Contact Us - ARED Group"
        description="Reach out to us for any inquiries or assistance."
      />
      <div className="container mx-auto py-10 px-6">
        <div className="text-center mb-8">
          <h1 className="text-5xl font-bold text-gray-800">Contact Us</h1>
          <p className="mt-2 text-lg text-gray-600">
            We’re here to help! Please fill out the form below, and we’ll get back to you shortly.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-start justify-between lg:space-x-8 mb-10">
          <div className="flex-1 ">
            <h2 className="text-2xl font-semibold text-[#01BAB4] mb-4">Our Address</h2>
            <MapPin className="w-8 h-8 text-primary-500 mb-2" />
            <p className="text-gray-700 whitespace-pre-wrap">{address}</p>
          </div>
          <div className="flex-1  mt-6 lg:mt-0">
            <h2 className="text-2xl font-semibold text-[#01BAB4] mb-4">Email Us</h2>
            <Mail className="w-8 h-8 text-primary-500 mb-2" />
            <a href={`mailto:${toEmail}`} className="text-[#01BAB4] hover:underline">
              {toEmail}
            </a>
          </div>
        </div>

        <form
          className="max-w-lg mx-auto bg-white p-8 rounded-md shadow-md"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl font-semibold text-[#01BAB4] mb-6 text-center">Send Us a Message</h2>

          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleInputChange}
              placeholder="Your Name"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-[#01BAB4] transition duration-200"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Your Email"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-[#01BAB4] transition duration-200"
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={handleInputChange}
              rows="4"
              placeholder="Your Message"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-[#01BAB4] transition duration-200"
              required
            ></textarea>
          </div>

          <Button
            type="submit"
            className="w-full bg-[#FDAF04] hover:bg-[#ff9900] text-white font-semibold py-2 rounded-md transition duration-200"
          >
            Send Message
          </Button>
        </form>

        <div className="mt-12 text-center">
          <h2 className="text-2xl font-semibold text-[#01BAB4] mb-4">Follow Us</h2>
          <div className="flex justify-center space-x-4">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700"
            >
              <FaFacebook className="w-8 h-8" />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-500"
            >
              <FaTwitter className="w-8 h-8" />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-600 hover:text-pink-700"
            >
              <FaInstagram className="w-8 h-8" />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-800"
            >
              <FaLinkedin className="w-8 h-8" />
            </a>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
