import React from 'react';
import { Mail, MapPin, Phone } from 'react-feather';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-[#01677E] py-10 px-8 md:px-24 lg:px-40 text-gray-100">
      <div className="container mx-auto flex flex-col md:flex-row md:justify-between space-y-10 md:space-y-0">
        {/* Contact Section */}
        <div className="flex-1 space-y-4">
          <h2 className="text-xl font-semibold">Contact Us</h2>
          <div className="space-y-3">
            <div className="flex items-center">
              <Mail className="h-5 w-5 text-gray-300 mr-2" />
              <span>info@aredgroup.com</span>
            </div>
            <div className="flex items-start">
              <MapPin className="h-5 w-5 text-gray-300 mr-2" />
              <address className="not-italic">
                ARED Group Headquarters <br />
                P.O Box 134920, Kampala <br />
                Plot 14051, Twin Plaza, Mbogo Road <br />
                Kira Town
              </address>
            </div>
          </div>
        </div>

        {/* Quick Links Section */}
        <div className="flex-1 space-y-4">
          <h2 className="text-xl font-semibold">Quick Links</h2>
          <div className="flex flex-col space-y-2">
            <Link to="/" className="hover:text-gray-200 transition">
              About Us
            </Link>
            <Link to="/" className="hover:text-gray-200 transition">
              Our Services
            </Link>
            <Link to="/" className="hover:text-gray-200 transition">
              Community Impact
            </Link>
            <Link to="/" className="hover:text-gray-200 transition">
              Careers
            </Link>
          </div>
        </div>
      </div>

      {/* Bottom Links */}
      <div className="container mx-auto mt-10 text-center text-gray-400 text-sm">
        <p className="space-x-4">
          <Link to="/" className="hover:text-gray-100 transition">
            Terms & Conditions
          </Link>{' '}
          |
          <Link to="/" className="hover:text-gray-100 transition">
            Lender Policy
          </Link>{' '}
          |
          <Link to="/" className="hover:text-gray-100 transition">
            Borrower Policy
          </Link>{' '}
          |
          <Link to="/" className="hover:text-gray-100 transition">
            General Policy
          </Link>
        </p>
        <p className="mt-4 text-xs">
          &copy; 2024 ARED Group. All rights reserved. <br />
        </p>
      </div>
    </footer>
  );
};
